import { IBenefit } from './benefits';
import { ICity } from './city';
import { IEmploymentLevel, IEmploymentType } from './employment-types';
import { IJobDetailsShow } from './job-details-show';
import { ILanguage } from './language';
import { IJobOccupation } from './occupations';
import { IRegion } from './region';

export interface IJobRole {
  id: number;
  name: string;
  createdAt?: string;
  isActive?: number;
  jobOccupationId?: number;
  slug?: string;
  weight?: number;
}

export interface IJobSkills {
  id: number;
  name: string;
}

export interface IJob {
  address: string;
  branchIoUrl?: string | null;
  cityId: number;
  companyId: number | null;
  companyName: string;
  createdAt: string;
  description: string | null;
  experienceYearsMax: number | null;
  experienceYearsMin: number;
  id: number;
  industryId: number;
  jobOccupationId: number | null;
  jobRole: IJobRole | null;
  jobRoleId: number | null;
  jobStatusId: number;
  notInterestedCount?: number;
  offerUrl?: string | null;
  publishedAt?: string | null;
  recruiterId: number;
  salaryMax: number | null;
  salaryMin: number;
  salaryRangeHidden: number;
  updatedAt: string;
  jobOccupation?: IJobOccupation | null;
  skills?: IJobSkills[];
  languages?: ILanguage[];
  employmentTypes?: IEmploymentType[];
  employmentLevels?: IEmploymentLevel[];
  workModelId?: number;
  benefits?: IBenefit[];
  city?: ICity;
  messageText?: string;
  regionId: null | number;
  regions: IRegion[];
  countries: string[];
}

export interface IActiveJobRecruiter {
  benefitSaveForFuture: number;
  companyId: number;
  firstName: string;
  id: number;
  languageId: number | null;
  lastName: string;
  newCandidateNotification: number;
  newMessageNotification: number;
  photoUrl: string | null;
  position: string | null;
  recruiterTeamId: number | null;
  updatedAt: string | null;
  userId: number | null;
}

export interface IActiveJobRole {
  id: number;
  name: string;
}

export interface IActiveJob {
  allCandidate: number;
  createdAt: string;
  id: number;
  jobRole?: IActiveJobRole;
  newCandidate: number;
  publishedAt: string | null;
  recruiter: IActiveJobRecruiter;
  updatedAt: string | null;
  atsId: string | null;
  komboIntegrationId: string | null;
}

export interface IJobsAmount {
  jobActive: number;
  jobDraft: number;
  jobArchive: number;
}

export interface ICandidateJobAssign {
  createdAt: string;
  id: number;
  jobRole: IJobRole | null;
  jobRoleId: number | null;
}

export const generateJobDetailsShareUrl = (
  jobDetails: IJobDetailsShow,
): string => {
  if (!jobDetails) return '';
  const { urlParams } = jobDetails;
  const { city, countryName, jobCategory, jobId, jobRole } = urlParams;

  return `${process.env.NX_CANDIDATES_WEB_URL}/${countryName}/${jobCategory}/${city}/${jobRole}/${jobId}`;
};
